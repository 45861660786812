import { Modal, Form, Input, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import 'src/assets/bootstrap/css/bootstrap.min.css';
import { Footer, Header, Helmet } from 'src/components';
import LocationComponent from 'src/components/Location';
import Banner from './components/Banner';
import Feature from './components/Feature';
import FreeConsultation from './components/FreeConsultation';
import InfomationContact from './components/InfomationContact';
// import ModalHome from './components/ModalHome';
import Partner from './components/Partner';
import WhyUseComponent from './components/WhyUseComponent';
import './styles.css';

const HomeScreen: React.FC<{}> = () => {
	return (
		<div className="home-v1">
			<Helmet
				title="ACheckin - Giải pháp quản trị nâng cao trải nghiệm nhân sự tốt nhất"
				contentDescription="ACheckin – Ứng dụng quản lý nhân sự, tài nguyên và dự án miễn phí đa nền tảng cho Doanh nghiệp: chấm công, xin nghỉ phép... trên iOS, Android, PC, Web/CMS"
				contentKeywords="phần mềm chấm công miễn phí, ứng dụng quản lý nhân sự, app chấm công, ứng dụng quản lý công việc, phần mềm quản lý dự án, quản lý hệ thống, quản lý tài nguyên"
				link="https://acheckin.vn"
			/>

			<header>
				<Header />
				<div className='padding-top-87'>
					<Banner />
				</div>
			</header>

			{/* <ModalHome /> */}

			{/* Tại sao nên sử dụng */}
			<WhyUseComponent />

			{/* Tính năng nổi bật */}
			<Feature />

			{/* Khách hàng */}
			<Partner />

			{/* Đăng ký nhận tư vấn  */}
			<div id='sign-up-home' className='bg-free-consultation'>
				<FreeConsultation />
			</div>

			{/* Liên hệ với chúng tôi */}
			<InfomationContact />

			{/* Thông tin vị trí */}
			<LocationComponent />

			<Footer />
		</div>
	)
}

export default HomeScreen;