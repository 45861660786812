import React from 'react';
import { Footer, Header, Helmet, TrialBox } from 'src/components';
import TrackingService from 'src/services/Tracking.Service';
import './styles.css';
import LocationComponent from 'src/components/Location';
import BannerContact from './components/BannerContact';
import FreeConsultationContact from '../Home/components/FreeConsultationContact';

const ContactScreen = () => {

	return (
		<div className="home-v1 contactpage">
			<Helmet
				title="Liên hệ với ACheckin - Giải pháp quản lý doanh nghiệp | ACheckin.vn"
				contentDescription="Liên hệ ngay với đội ngũ  ACheckin để được chúng tôi hỗ trợ, tư vấn và giải đáp mọi thắc mắc nhanh chóng"
				contentKeywords="liên hệ acheckin, lien he acheckin"
				link="https://acheckin.vn/contact"
			/>

			<header className='header-contact'>
				<Header />
				<BannerContact />

				{/* Đăng ký nhận tư vấn  */}
				<div className='container border-radius-16'>
					<FreeConsultationContact />
				</div>
			</header>

			<div className="infomation-contact">
				<div className="container">
					<div className="inner">
						<div className="row">
							<div className="col-sm-4">
								<div className="box d-flex">
									<div className="icon">
										<img alt="Liên hệ với ACheckin 2" src={require('src/assets/images/homev1/icons8-chat.png')} height={48} />
									</div>
									<div className="content">
										<h6>Hotline</h6>
										<p className="cl1"><a onClick={() => TrackingService.onTrackEvent("Contact", "Hotline")}>0886247001</a></p>
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="box d-flex">
									<div className="icon">
										<img alt="Liên hệ với ACheckin 3" src={require('src/assets/images/homev1/icons8-email.png')} height={48} />
									</div>
									<div className="content">
										<h6>Góp ý & hỗ trợ</h6>
										<p className="cl1"><a onClick={() => TrackingService.onTrackEvent("Contact", "Góp ý & hỗ trợ")} href="mailto:sale@acheckin.vn">sale@acheckin.vn</a></p>
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="box d-flex">
									<div className="icon">
										<img alt="Liên hệ với ACheckin 4" src={require('src/assets/images/homev1/icons8-facebook.png')} height={48} />
									</div>
									<div className="content">
										<h6>Fanpage</h6>
										<p className="cl1"><a onClick={() => TrackingService.onTrackEvent("Contact", "Fanpage")} href="https://fb.com/acheckin.appota" target="_blank">https://fb.com/acheckin.appota</a></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* Thông tin vị trí */}
			<LocationComponent />
			<Footer />
		</div>
	)
}

export default ContactScreen;
