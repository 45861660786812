//@ts-ignore
import $ from 'jquery';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import React from 'react'
interface Props {
}

const Header: React.FC<Props> = (props) => {

  const [activeMenu, setActiveMenu] = useState(false);
  const [stickyClass, setStickyClass] = useState('')

  useEffect(() => {
    $('header nav').click(function () {
      setActiveMenu(!activeMenu);
    })

    $(document).bind("mouseup touchend", function () {
      setActiveMenu(false);
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, [])

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500 ? setStickyClass('menu-scroll') : setStickyClass('')
    }
  };

  const MENU = [
    { title: "Trang chủ", to: "/" },
    { title: "Tính năng", to: "/feature" },
    { title: "HRM", to: "/hrm" },
    { title: "Hướng dẫn", to: "https://help.acheckin.io/", target: true },
    { title: "Download", to: "/download" },
    { title: "Liên hệ", to: "/contact" },
    { title: "Blog", to: "https://news.acheckin.vn/", target: true },
    { title: "Dùng thử miễn phí", to: "/register", trial: true },
  ]

  return (
    <>
      <div className={`menu-header ${stickyClass}`}>
        <div className="container header-top clearfix">
          <div className="logo float-left">
            <NavLink to="/">
              <img alt="ACheckin - Ứng dụng chấm công và quản lý tài nguyên Doanh Nghiệp" src={require('src/assets/images/homev1/logo.png')} height={40} />
            </NavLink>
          </div>
          <nav className="float-right">
            <span className="icon-menu"><img src={require('src/assets/images/icon-menu.png')} width={35} /></span>
            <ul className={activeMenu ? "list-inline active" : "list-inline"}>
              {MENU.map((d, key) => (
                <li key={`menu_${key + 1}`} className="list-inline-item">
                  <NavLink
                    exact
                    to={{ pathname: d.to }} target={d.target ? "_blank" : ""}
                    className={d.trial ? "linktrial" : ""}
                  >
                    {d.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      <section className="call-for-ip">
        <div className="border-call">
          <a href="tel:0886247001">
            <img src={require('src/assets/images/homev1/phone.png')} />
          </a>
        </div>
      </section>
    </>
  )
}

export default React.memo(Header);